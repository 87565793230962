























































































import Vue from 'vue'
import { mapGetters } from 'vuex';
import { formatMultiselectEmit } from '@/models/core/FilterPanel';
import { DashboardCriteriaModel } from '@/models/filterpanel/DashboardCriteriaModel';
import { DepartmentDto } from '@/models/planning/DepartmentDto';
import { EducationalDirectionDto } from '@/models/planning/EducationalDirectionDto';
import { SchoolSubjectDto } from '@/models/planning/SchoolSubjectDto';

export default Vue.extend({
  name: 'dashboard-filterpanel',
  props: {
    filterSchoolTerm: {
      type: String,
      required: false
    },
    rootLoading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    selectedDepartments: null,
    selectedEducationalDirections: null,
    selectedSchoolSubjects: null,
  }),
  methods: {
    emitSelection() {
      const value:DashboardCriteriaModel = {
        departments: formatMultiselectEmit(this.selectedDepartments, (q: DepartmentDto) => q.id),
        educationalDirections: formatMultiselectEmit(this.selectedEducationalDirections, (q: EducationalDirectionDto) => q.id),
        schoolSubjects: formatMultiselectEmit(this.selectedSchoolSubjects, (q: SchoolSubjectDto) => q.id)
      };
      this.$emit('update:selection', value);
    },
    clearSelection() {
      this.selectedDepartments = null;
      this.selectedEducationalDirections = null;
      this.selectedSchoolSubjects = null;
    }
  },
  computed: {
    ...mapGetters('departments', ['departments']),
    ...mapGetters('educationDirectionsStore', {educationalDirections: 'educationDirections'}),
    ...mapGetters('schoolSubjectsStore', ['schoolSubjects']),
    allEducationalDirections() {
      return this.educationalDirections();
    }

  },
  watch: {
    'filterSchoolTerm'() {
      this.clearSelection();
    }
  }
})
