



























































import Vue from 'vue';
import APISTATE from '@/enums/APISTATE';
import { mapActions, mapGetters } from 'vuex';
import { SchoolTermDto } from '@/models/planning/SchoolTermDto';
import SectionHeader from '@/components/SectionHeader.vue';
import DashboardFilterPanel from './filterpanels/DashboardFilterPanel.vue';
import { DashboardCriteriaModel } from '@/models/filterpanel/DashboardCriteriaModel';
import { DashboardViewModel } from '@/models/dashboard/DashboardViewModel';

export default Vue.extend({
  components: { SectionHeader, DashboardFilterPanel },
  name: "dashboard-view",
  data: () => ({
    filterSchoolTerm: undefined as SchoolTermDto | undefined,
    groupBy: undefined as string,
    criteriaModel: undefined as DashboardCriteriaModel | undefined,
    viewModel: {} as DashboardViewModel,
  }),
  async mounted() {
    this.updatePageTitle('Dashboard');

    await Promise.all([
      this.loadSchoolTerms(),
      this.loadDepartments(),
      this.loadEducationalDirections(),
      this.loadSchoolSubjects(),
      this.loadTabGroupOptions(),
    ]);

    this.applyGlobalSchoolTermSelection();
  },
  methods: {
    ...mapActions(['updatePageTitle', 'updateGlobalSchoolTermSelection']),
    ...mapActions('schoolTermStore', ['loadSchoolTerms']),
    ...mapActions('departments', ['loadDepartments']),
    ...mapActions('educationDirectionsStore', ['loadEducationalDirections']),
    ...mapActions('schoolSubjectsStore', ['loadSchoolSubjects']),
    ...mapActions('dashboardStore', ['loadTabGroupOptions']),
    applyGlobalSchoolTermSelection() {
      const schoolTerm = this.schoolTerms.find((q:SchoolTermDto) => q.id === this.globalSchoolTermSelection);
      if (schoolTerm) {
        this.filterSchoolTerm = schoolTerm;
      }
    },
    updateData() {
      this.viewModel = {
        schoolTerm: this.filterSchoolTerm.id,
        criteriaModel: this.criteriaModel,
        groupBy: this.groupBy
      } as DashboardViewModel;
    },
    updateFilterSelection(criteriaModel:DashboardCriteriaModel) {
      this.criteriaModel = criteriaModel;
      this.updateData();
    }


  },
  computed: {
    ...mapGetters(['globalSchoolTermSelection']),
    ...mapGetters('schoolTermStore', ['schoolTermApiState', 'schoolTerms']),
    ...mapGetters('departments', {isLoadingDepartments: 'isLoading'}),
    ...mapGetters('educationDirectionsStore', {educationalDepartmentsApiState: 'state'}),
    ...mapGetters('schoolSubjectsStore', {isLoadingSchoolSubjects: 'isLoading'}),
    ...mapGetters('dashboardStore', ['tabGroupOptions']),
    filterSchoolTermProp() {
      if (this.filterSchoolTerm) {
        return this.filterSchoolTerm.id;
      }
      return undefined;
    },
    isLoading() {
      return this.isLoadingDepartments
        || this.isLoadingSchoolSubjects
        || this.educationalDepartmentsApiState === APISTATE.LOADING
        || this.schoolTermApiState === APISTATE.LOADING;
    },
    tabs() {
      return [
        { title: "Holdgennemsnit", to: "/dashboard" },
        { title: "Undervisningstimer", to: "/dashboard/undervisningstimer" },
        { title: "Årselever", to: "/dashboard/aarselever" },
      ];
    },
    groupByProperty: {
      get() {
        return this.tabGroupOptions.find(q => q.key === this.groupBy);
      },
      set(value:any) {
        this.groupBy = value.key
      }
    }
  },
  watch: {
    async 'filterSchoolTermProp'(newValue) {
      // await Promise.all([
      //   this.loadClassSubjectsBySchoolTerm(newValue),
      //   this.loadProjectsBySchoolTerm(newValue),
      //   this.loadTeachersBySchoolTerm(newValue),
      //   this.loadTeacherTermsBySchoolTerm(newValue),
      //   this.loadDistributions(newValue),
      //   this.loadClassSubjectStudentTypesByTerm(newValue),
      // ]);
      // await this.attachTeachersTermsAndSubjects();
      this.updateGlobalSchoolTermSelection(newValue);
      this.updateData();
    }
  }
})
